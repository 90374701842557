import { InfoCircledIcon } from '@radix-ui/react-icons'
import { Callout, Flex, Text, TextField } from '@radix-ui/themes'
import { getCsrfToken } from 'next-auth/react'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { useState } from 'react'
import { Title as DialogTitle } from '../../components/dialog'
import * as Form from '../../components/form'
import { Link } from '../../components/link'
import { API, useService } from '../../hooks/use-api'
import styles from '../auth.module.css'

export { ResetPage }

function ResetPage({
  params: [resetId],
  query: { organizationId, error, name: email },
  validation,
}) {
  const Page = resetId ? ResetPasswordPage : ResetPasswordRequestPage

  return (
    <Page
      email={email}
      error={error}
      organization={organizationId}
      resetId={resetId}
      validation={validation}
    />
  )
}

function ResetPasswordPage({ resetId, error, email, validation }) {
  const router = useRouter()
  const { callPost: verifyInvite } = useService(API('/auth/verify'))

  return (
    <>
      <Head>
        <title>Enable account</title>
      </Head>

      <DialogTitle className={styles.heading}>Enable account</DialogTitle>
      <Flex direction="column" gap="3">
        <Form.Root action={enableAccount} initialMessage={error} method="post">
          <Form.Field hidden name="id" required>
            <Form.Control asChild>
              <TextField.Root value={resetId} />
            </Form.Control>
          </Form.Field>

          <Form.Field hidden={!!email} name="email" required>
            {!email && (
              <Form.Label>
                <Text>E-mail</Text>
              </Form.Label>
            )}
            <Form.Control asChild>
              <TextField.Root
                autoComplete="username"
                placeholder="alice@company.com"
                type={email ? 'hidden' : 'email'}
                value={email ?? undefined}
              />
            </Form.Control>
          </Form.Field>

          <Form.Field name="pin" required>
            <Form.Label>
              <Text>PIN code</Text>
            </Form.Label>
            <Form.Control asChild>
              <TextField.Root
                autoComplete="off"
                placeholder="123456"
                type="text"
              />
            </Form.Control>
          </Form.Field>

          <Form.Field name="password" required>
            <Form.Label>
              <Text>New password</Text>
            </Form.Label>
            {...validation.password.messages}
            <Form.Control asChild>
              <TextField.Root
                {...validation.password.rules}
                autoComplete="new-password"
                type="password"
              />
            </Form.Control>
          </Form.Field>

          <Form.Field name="confirmPassword" required>
            <Form.Label>
              <Text>Confirm password</Text>
            </Form.Label>
            {...validation.password.messages}
            <Form.ValidationMessage
              match={(value, formData) => {
                const password = formData.get('password')
                return password && value !== password
              }}
            >
              passwords must match
            </Form.ValidationMessage>
            <Form.Control asChild>
              <TextField.Root
                {...validation.password.rules}
                autoComplete="new-password"
                placeholder="••••••••"
                type="password"
              />
            </Form.Control>
          </Form.Field>

          <Form.Submit mt="5">Enable account</Form.Submit>
        </Form.Root>
      </Flex>
    </>
  )

  async function enableAccount(formData) {
    const body = {
      ...Object.fromEntries(formData),
      csrfToken: await getCsrfToken(),
    }

    const verification = await verifyInvite({ body })

    if (verification.error) {
      throw verification.error
    } else {
      const searchParams = new URLSearchParams({
        organizationId: verification.data.result.organization_id,
        message: 'AccountEnabled',
        email: body.email,
      })

      await router.replace(`/auth/signin?${searchParams}`)
    }
  }
}

function ResetPasswordRequestPage({
  email: initialEmail,
  error: initialError,
  organization,
  validation,
}) {
  const [email, setEmail] = useState(initialEmail)
  const [success, setSuccess] = useState(false)

  const { callPost: requestReset } = useService(API('/auth/reset'))

  return (
    <>
      <Head>
        <title>Reset password</title>
      </Head>

      <DialogTitle className={styles.heading}>Reset password</DialogTitle>
      <Flex direction="column" gap="6">
        <Flex direction="column" gap="4">
          <Text>
            Enter your e-mail to request a link to reset your password.
          </Text>
          {success ? (
            <Callout.Root>
              <Callout.Icon>
                <InfoCircledIcon />
              </Callout.Icon>
              <Callout.Text>
                Please check your e-mail for further instructions on how to
                reset your password.
              </Callout.Text>
            </Callout.Root>
          ) : (
            <Form.Root
              action={resetPassword}
              className={styles.form}
              initialMessage={initialError}
            >
              <Form.Field name="email">
                <Form.Label>
                  <Text>E-mail</Text>
                </Form.Label>
                {...validation.email.messages}
                <Form.Control asChild>
                  <TextField.Root
                    {...validation.email.rules}
                    onChange={({ target }) => setEmail(target.value)}
                    placeholder="alice@company.com"
                    required
                    type="email"
                    value={email ?? ''}
                  />
                </Form.Control>
              </Form.Field>

              <Form.Submit mt="5">Reset password</Form.Submit>
            </Form.Root>
          )}

          <Text align="center" size="1">
            <Link href="/auth/signin">Sign In</Link>
          </Text>
        </Flex>
      </Flex>
    </>
  )

  async function resetPassword(formData) {
    const body = {
      ...Object.fromEntries(formData),
      organization,
      csrfToken: await getCsrfToken(),
    }

    const reset = await requestReset({ body })

    if (reset.error?.status >= 500) {
      throw reset.error
    } else {
      setSuccess(true)
    }
  }
}
